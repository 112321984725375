import Redirect from './components/Redirect/Redirect'

function App () {
  return (
    // <>
    //   <Router>
    //     <Routes>
    //       <Route path="/redirect" element={<Redirect/>}/>
    //     </Routes>
    //   </Router>
    // </>
    <Redirect/>
  )
}

export default App

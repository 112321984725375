import './Redirect.css'

const Redirect = () => {

  return (
    <div className="redirect-container">
      <img src='/images/bank.png' alt='Return to Future App'/>
      <p>You can now close this window and return to Future.</p>
    </div>
  )
}

export default Redirect
